import React from "react"

import Layout, { Article, ArticleHeader, Section } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <Article>
        <ArticleHeader title="You might be lost!" />
        <Section>Yup - nothing here...</Section>
      </Article>
    </Layout>
  )
}

export default NotFoundPage
